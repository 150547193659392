import "react-perfect-scrollbar/dist/css/styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import 'react-image-lightbox/style.css'
import 'react-tagsinput/react-tagsinput.css'
import 'react-date-picker/dist/DatePicker.css'

import {storage, defaultTheme} from "./constants/defaultValues"

const color = localStorage.getItem(storage.theme) || defaultTheme

localStorage.setItem(storage.theme, color)

let render = () => {
    import('./assets/css/sass/themes/crm.' + color + '.scss').then(x => {
        require('./AppRenderer')
    })
}
render()