import { combineReducers } from 'redux'
import {colorTheme, leftSidebar, dashboardLayout, dragAndDropLayout} from './settings/reducer'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import themesAvailable from './themesAvailable/reducer'
import info from './info/reducer'
import infoLogin from './info-login/reducer'
import tokens from './tokens/reducer'
import item from './item/reducer'
import clients from './clients/reducer'
import partners from './partners/reducer'
import myContracts from './my-contracts/reducer'
import administrators from './administrators/reducer'
import strings from './strings/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'

import enums from './enums/reducer'

export default combineReducers({
  toaster,
  colorTheme,
  dashboardLayout,
  dragAndDropLayout,
  leftSidebar,
  themesAvailable,
  auth,
  info,
  infoLogin,
  tokens,
  item,
  clients,
  partners,
  myContracts,
  administrators,
  strings,
  adminStrings,
  enums,
  filters
})