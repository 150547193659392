import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import {configureStore} from './redux/store'
import SuspenseFallback from "./components/SuspenseFallback"

const App = React.lazy(() => import('./App' ))

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<SuspenseFallback/>}>
        <App/>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)
