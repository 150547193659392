import {
    REQUEST_TOKENS,
    RECEIVE_TOKENS,
    ERROR_TOKENS
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_TOKENS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_TOKENS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                message: action.message,
            }
        case ERROR_TOKENS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        default:
            return {...state}
    }
}