import {
    REQUEST_ITEM,
    RECEIVE_ITEM,
    UPDATE_ITEM,
    ERROR_ITEM
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_ITEM:
            return {
                ...state,
                isFetching: true,
                data: {},
                key: action.payload,
                message: '',
                errors: {}
            }
        case RECEIVE_ITEM:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                message: action.message,
            }
        case UPDATE_ITEM:
            const data = state.data
            switch(action.updateType) {
                case 'loginLog':
                    data.logs.login = action.payload
                    break
            }
            return {
                ...state,
                data
            }
        case ERROR_ITEM:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: '',
            }
        default:
            return {...state}
    }
}