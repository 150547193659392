import {
    REQUEST_ENUMS,
    RECEIVE_ENUMS,
    RELOAD_ENUMS,
    ERROR_ENUMS
} from '../types'
import {initialEnumFetchState, initialEnumItemFetchState} from '../../constants/defaultValues'

export default (state = initialEnumFetchState, action = {}) => {
    let newState = {...state}
    switch(action.type) {
        case REQUEST_ENUMS:
            newState[action.enumType] = {
                ...initialEnumItemFetchState,
                isFetching: true,
            }
            return newState
        case RECEIVE_ENUMS:
            newState[action.enumType] = {
                ...newState[action.enumType],
                isFetching: false,
                data: action.payload,
                shouldReload: false
            }
            return newState
        case RELOAD_ENUMS:
            if(newState[action.enumType]){
                newState[action.enumType] = {
                    ...newState[action.enumType],
                    shouldReload: true,
                }
            }
            return newState
        case ERROR_ENUMS:
            newState[action.enumType] = {
                ...newState[action.enumType],
                isFetching: false,
                data: [],
            }
            return newState
        default:
            return newState
    }
}