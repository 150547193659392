import {
    REQUEST_CURRENT_USER,
    RECEIVE_CURRENT_USER,
    ERROR_CURRENT_USER
} from '../types'
import {initialUserFetchState} from '../../constants/defaultValues'
import isEmpty from "lodash/isEmpty"

export default (state = initialUserFetchState, action) => {
    switch (action.type) {
        case REQUEST_CURRENT_USER:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: true,
                user: {}
            }
        case ERROR_CURRENT_USER:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                user: {}
            }
        case RECEIVE_CURRENT_USER:
            const payload = {...action.payload, ...action.payload.profile}
            const permissions = []
            payload.permissions.map(perm => {
                permissions.push(perm.replace("user.crm.", "").replace("crm.", ""))
            })
            payload.permissions = permissions
            delete payload.profile

            return {
                ...state,
                isFetching: false,
                user: payload,
                isAuthenticated: !isEmpty(payload)
            }
        default:
            return {...state}
    }
}
