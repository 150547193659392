import {defaultTheme, themeOptions, defaultLeftSidebar, storage} from '../../constants/defaultValues'

import {
	CHANGE_THEME,
    CHANGE_LEFT_SIDEBAR,
    CHANGE_DASHBOARD_LAYOUT,
    CHANGE_DRAGANDDROP_LAYOUT
} from '../types'


const INIT_STATE_THEME = (localStorage.getItem(storage.theme) && themeOptions[localStorage.getItem(storage.theme)]) ? localStorage.getItem(storage.theme) : defaultTheme

const INIT_STATE_LAYOUT = localStorage.getItem(storage.dashboardLayout) ? JSON.parse(localStorage.getItem(storage.dashboardLayout)) : {}

const INIT_STATE_LEFT_SIDEBAR = (localStorage.getItem(storage.leftSidebar) && ['', 'collapsed'].filter(x => x === localStorage.getItem(storage.leftSidebar)).length > 0) ? localStorage.getItem(storage.leftSidebar) : defaultLeftSidebar

export const colorTheme = (state = INIT_STATE_THEME, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            let theme = null
            if(action.override){
                theme = action.payload
            }else{
                theme = state
            }
            if(theme !== state){
                localStorage.setItem(storage.theme, action.payload)
                window.location.reload()
            }

            return theme
        default:
            return state
    }
}

export const leftSidebar = (state = INIT_STATE_LEFT_SIDEBAR, action) => {
    switch (action.type) {
        case CHANGE_LEFT_SIDEBAR:
            localStorage.setItem(storage.leftSidebar, action.payload)
            return action.payload
        default:
            return state
    }
}

export const dashboardLayout = (state = INIT_STATE_LAYOUT, action) => {
    switch (action.type) {
        case CHANGE_DASHBOARD_LAYOUT:
            const layout = {...state}
            layout[action.dashboard] = action.payload
            localStorage.setItem(storage.dashboardLayout, JSON.stringify(layout))
            return layout
        default:
            return state
    }
}

export const dragAndDropLayout = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_DRAGANDDROP_LAYOUT:
            const obj = {...state}
            obj[action.payload] = obj[action.payload] ? false : true
            return obj
        default:
            return state
    }
}