import {
    REQUEST_MY_CONTRACTS,
    RECEIVE_MY_CONTRACTS,
    ERROR_MY_CONTRACTS
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_MY_CONTRACTS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_MY_CONTRACTS:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                message: '',
            }
        case ERROR_MY_CONTRACTS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return {...state}
    }
}