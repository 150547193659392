import {
    REQUEST_INFO_LOGIN,
    RECEIVE_INFO_LOGIN,
    ERROR_INFO_LOGIN
} from '../types'
import {initialFetchState} from '../../constants/defaultValues'

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_INFO_LOGIN:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_INFO_LOGIN:
            const payload = action.payload
            payload.activeModules = []
            payload.modules.map(module => {
                if(module.active){
                    payload.activeModules.push(module.module__code)
                }
            })
            return {
                ...state,
                isFetching: false,
                data: payload,
                message: action.message,
            }
        case ERROR_INFO_LOGIN:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        default:
            return {...state}
    }
}