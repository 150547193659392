/*
LocalStorage keys
*/
export const storage = {
    theme: '__theme_color',
    leftSidebar: '__left_sidebar',
    authentication: 'Authorization_' + btoa(window.location.host),
    dashboardLayout: '__dashboard_layout'
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

/* 
Theme Options:
"light.default", "dark.default"
*/
export const defaultLeftSidebar = ''
export const defaultTheme = "light.default"
export const themeOptions = {
    "light.default": {
        isLight: true,
        primary: '#0090B4',
        secondary: '#B32400'
    },
    "dark.default": {
        primary: '#0090B4',
        secondary: '#B32400'
    },
    "light.green": {
        isLight: true,
        primary: '#3cb43e',
        secondary: '#b3862b'
    },
    "dark.green": {
        primary: '#3cb43e',
        secondary: '#b3862b'
    }
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean' : {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

