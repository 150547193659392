/* SETTINGS */
export const CHANGE_THEME = "CHANGE_THEME"
export const CHANGE_LEFT_SIDEBAR = "CHANGE_LEFT_SIDEBAR"
export const CHANGE_DASHBOARD_LAYOUT = "CHANGE_DASHBOARD_LAYOUT"
export const CHANGE_DRAGANDDROP_LAYOUT = "CHANGE_DRAGANDDROP_LAYOUT"

/*TOASTER*/
export const SET_TOASTER = "SET_TOASTER"

/* FILTERS */
export const SET_FILTER_DATA = "SET_FILTER_DATA"
export const TOGGLE_FILTER = "TOGGLE_FILTER"

/* AUTH */
export const REQUEST_CURRENT_USER = "REQUEST_CURRENT_USER"
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"
export const ERROR_CURRENT_USER = "ERROR_CURRENT_USER"

/* INFO */
export const REQUEST_INFO = "REQUEST_INFO"
export const RECEIVE_INFO = "RECEIVE_INFO"
export const ERROR_INFO = "ERROR_INFO"

/* INFO-LOGIN */
export const REQUEST_INFO_LOGIN = "REQUEST_INFO_LOGIN"
export const RECEIVE_INFO_LOGIN = "RECEIVE_INFO_LOGIN"
export const ERROR_INFO_LOGIN = "ERROR_INFO_LOGIN"

/* TOKENS */
export const REQUEST_TOKENS = "REQUEST_TOKENS"
export const RECEIVE_TOKENS = "RECEIVE_TOKENS"
export const ERROR_TOKENS = "ERROR_TOKENS"

/* AVAILABLE THEMES */
export const REQUEST_AVAILABLE_THEMES = "REQUEST_AVAILABLE_THEMES"
export const RECEIVE_AVAILABLE_THEMES = "RECEIVE_AVAILABLE_THEMES"
export const ERROR_AVAILABLE_THEMES = "ERROR_AVAILABLE_THEMES"

/* STRINGS */
export const REQUEST_STRINGS = "REQUEST_STRINGS"
export const RECEIVE_STRINGS = "RECEIVE_STRINGS"
export const UPDATE_STRINGS = "UPDATE_STRINGS"
export const ERROR_STRINGS = "ERROR_STRINGS"
export const TOGGLE_DEV_STRINGS = "TOGGLE_DEV_STRINGS"

/*ADMIN STRINGS*/
export const REQUEST_ADMIN_STRINGS = "REQUEST_ADMIN_STRINGS"
export const RECEIVE_ADMIN_STRINGS = "RECEIVE_ADMIN_STRINGS"
export const UPDATE_ADMIN_STRINGS = "UPDATE_ADMIN_STRINGS"
export const ERROR_ADMIN_STRINGS = "ERROR_ADMIN_STRINGS"

/* PARTNERS */
export const REQUEST_PARTNERS = "REQUEST_PARTNERS"
export const RECEIVE_PARTNERS = "RECEIVE_PARTNERS"
export const UPDATE_PARTNERS = "UPDATE_PARTNERS"
export const ERROR_PARTNERS = "ERROR_PARTNERS"

/* CLIENTS */
export const REQUEST_CLIENTS = "REQUEST_CLIENTS"
export const RECEIVE_CLIENTS = "RECEIVE_CLIENTS"
export const UPDATE_CLIENTS = "UPDATE_CLIENTS"
export const ERROR_CLIENTS = "ERROR_CLIENTS"

/* MY CONTRACTS */
export const REQUEST_MY_CONTRACTS = "REQUEST_MY_CONTRACTS"
export const RECEIVE_MY_CONTRACTS = "RECEIVE_MY_CONTRACTS"
export const ERROR_MY_CONTRACTS = "ERROR_MY_CONTRACTS"

/* ADMINS */
export const REQUEST_ADMINISTRATORS = "REQUEST_ADMINISTRATORS"
export const RECEIVE_ADMINISTRATORS = "RECEIVE_ADMINISTRATORS"
export const UPDATE_ADMINISTRATORS = "UPDATE_ADMINISTRATORS"
export const ERROR_ADMINISTRATORS = "ERROR_ADMINISTRATORS"

/* ENUMS */
export const REQUEST_ENUMS = "REQUEST_ENUMS"
export const RECEIVE_ENUMS = "RECEIVE_ENUMS"
export const RELOAD_ENUMS = "RELOAD_ENUMS"
export const ERROR_ENUMS = "ERROR_ENUMS"

/* ITEM */
export const REQUEST_ITEM = "REQUEST_ITEM"
export const RECEIVE_ITEM = "RECEIVE_ITEM"
export const UPDATE_ITEM = "UPDATE_ITEM"
export const ERROR_ITEM = "ERROR_ITEM"
